import request from '@/utils/request'
import serviceConfig from '@/api/serviceConfig'
// 2023.3.20
// 之前6积分是用订单号与券码编号兑换的容易被发现规律，所以为了修补漏洞改成兑换码形式来兑换为了安全后端接口全部重写，前端要兼容又不能改动路由需要兼容判断
/**
 * 兑换
 */
export function reqXyExchangeCoupon(data) {
  return request({
    url: `${serviceConfig.CUSTOM}/industrialBank/exchangeRedeemCodeNo`,
    method: 'post',
    data
  })
}

// 校验兑换码是否兑换
export function reqXyCheckCoupon(data) {
  return request({
    url: `${serviceConfig.CUSTOM}/industrialBank/checkRedeemCodeNo`,
    method: 'post',
    data
  })
}

// 校验是否直充兑换码
export function checkDirectCode(data) {
  return request({
    url: `${serviceConfig.CUSTOM}/industrialBank/checkDirectRedeemCodeNo`,
    method: 'post',
    data
  })
}

// 获取兴业业务获取卡密和商品使用说明
export function reqXyGetCoupon(data) {
  return request({
    url: `${serviceConfig.IMS}/outStorageDetails/getCardInfoByRedeemCodeNo`,
    method: 'post',
    data
  })
}

// 券码刮开通知
export function reqXyOpenNotify(data) {
  return request({
    url: `${serviceConfig.IMS}/redeemCodeStream/openNotifyByRedeemCodeNo`,
    method: 'post',
    data
  })
}
