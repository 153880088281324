import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: 50000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 0) {
      return Promise.resolve(res)
    } else if (res.code === 503) {
      router.replace({ name: 'upgrade' })
    } else {
      Toast.fail({
        message: res.message,
        duration: 3000
      })
      return Promise.reject(response)
    }
  },
  error => {
    Toast.fail('服务器异常，请稍后重试')
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
